import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106')
];

export const server_loads = [2,3,4,7,8,9,10,12,11,14,15,17];

export const dictionary = {
		"/": [~19],
		"/_test": [~96],
		"/(app)/account/keys": [20,[2]],
		"/(app)/account/settings": [21,[2]],
		"/(app)/analytics/claims": [~22,[2,3]],
		"/(app)/analytics/claims/claims": [23,[2,3]],
		"/(app)/analytics/claims/insights": [~24,[2,3]],
		"/(app)/analytics/claims/overview": [~25,[2,3]],
		"/(app)/analytics/gross-written-premium": [~26,[2,3]],
		"/(app)/analytics/gross-written-premium/insights": [~27,[2,3]],
		"/(app)/analytics/gross-written-premium/overview": [~28,[2,3]],
		"/(app)/analytics/gross-written-premium/policies": [29,[2,3]],
		"/(app)/analytics/loss": [~30,[2,3]],
		"/(app)/analytics/loss/claims": [31,[2,3]],
		"/(app)/analytics/loss/insights": [~32,[2,3]],
		"/(app)/analytics/loss/overview": [~33,[2,3]],
		"/(app)/analytics/rate-level": [~34,[2,3]],
		"/(app)/analytics/rate-level/insights": [~35,[2,3]],
		"/(app)/analytics/rate-level/overview": [~36,[2,3]],
		"/(app)/analytics/rate-level/policies": [37,[2,3]],
		"/(app)/analytics/renewals": [~38,[2,3]],
		"/(app)/analytics/renewals/insights": [~39,[2,3]],
		"/(app)/analytics/renewals/overview": [~40,[2,3]],
		"/(app)/analytics/renewals/policies": [41,[2,3]],
		"/(app)/analytics/retention": [~42,[2,3]],
		"/(app)/analytics/retention/insights": [~43,[2,3]],
		"/(app)/analytics/retention/overview": [~44,[2,3]],
		"/(app)/analytics/retention/policies": [45,[2,3]],
		"/auth/accept-invitation": [97,[18]],
		"/auth/accept-invitation/[code]": [98,[18]],
		"/auth/account-recovery": [~99,[18]],
		"/auth/account-recovery/[code]": [100,[18]],
		"/auth/mfa/setup": [101,[18]],
		"/auth/mfa/verify": [102,[18]],
		"/auth/signin": [~103,[18]],
		"/auth/signout": [104,[18]],
		"/auth/terms": [~105,[18]],
		"/(app)/bdx/history": [~46,[2,4]],
		"/(app)/bdx/overview": [~47,[2,4]],
		"/(app)/bdx/submissions/new": [~49,[2,4]],
		"/(app)/bdx/submissions/[submission_id]/status": [~48,[2,4]],
		"/(app)/directory": [50],
		"/(app)/insurer-appetite-forms": [55,[2,7]],
		"/(app)/insurer-appetite-forms/new": [57,[2,7]],
		"/(app)/insurer-appetite-forms/[id]": [56,[2,7]],
		"/(app)/insurer/analytics/claims": [51,[2,5,6]],
		"/(app)/insurer/analytics/gwp": [52,[2,5,6]],
		"/(app)/insurer/analytics/loss": [53,[2,5,6]],
		"/(app)/insurer/overview": [54,[2,5]],
		"/(app)/labs": [58,[2]],
		"/(app)/labs/wildfire": [~59,[2]],
		"/(app)/members": [~60,[2]],
		"/(app)/organizations": [~61,[2]],
		"/(app)/organizations/new": [~68,[2]],
		"/(app)/organizations/[org_id]": [~62,[2,8]],
		"/(app)/organizations/[org_id]/accelerant-team": [~63,[2,8]],
		"/(app)/organizations/[org_id]/directory-information": [64,[2,8,9]],
		"/(app)/organizations/[org_id]/directory-information/edit": [65,[2,8,9]],
		"/(app)/organizations/[org_id]/general": [66,[2,8,10]],
		"/(app)/organizations/[org_id]/general/edit": [~67,[2,8,10]],
		"/(app)/overview": [~69,[2]],
		"/(app)/planning": [~70,[2]],
		"/(app)/planning/cycles": [74,[2]],
		"/(app)/planning/cycles/new": [78,[2]],
		"/(app)/planning/cycles/[cycle_id]": [~75,[2,12]],
		"/(app)/planning/cycles/[cycle_id]/details": [76,[2,12,13]],
		"/(app)/planning/cycles/[cycle_id]/details/edit": [77,[2,12,13]],
		"/(app)/planning/[plan_id]": [~71,[2,11]],
		"/(app)/planning/[plan_id]/allocation-summary": [~72,[2,11]],
		"/(app)/planning/[plan_id]/monthly-plan": [73,[2,11]],
		"/(app)/reporting": [~79,[2,14]],
		"/(app)/reporting/new": [~82,[2,14]],
		"/(app)/reporting/[report_id]": [80,[2,14,15]],
		"/(app)/reporting/[report_id]/edit": [81,[2,14,15]],
		"/(app)/risk-scoring": [~83,[2]],
		"/(app)/risk-scoring/models": [84,[2]],
		"/(app)/risk-scoring/models/[id]": [85,[2]],
		"/(app)/risk-scoring/predictions": [86,[2]],
		"/(app)/risk-scoring/predictions/[id]": [87,[2]],
		"/(app)/underwriting-referrals": [~88,[2]],
		"/(app)/underwriting-referrals/[referral_id]": [~89,[2,16]],
		"/(app)/underwriting-referrals/[referral_id]/edit": [~90,[2,16]],
		"/unsubscribe": [~106],
		"/(app)/users": [~91,[2]],
		"/(app)/users/invite": [~94,[2]],
		"/(app)/users/new": [~95,[2]],
		"/(app)/users/[user_id]": [92,[2,17]],
		"/(app)/users/[user_id]/edit": [93,[2,17]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';